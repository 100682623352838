import { SignIn, SignedIn } from "@clerk/remix";
import { Button } from "@infinitenz/pyrite";
import { type LoaderFunctionArgs, json } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import invariant from "tiny-invariant";

import { getRedirectURL } from "~/modules/policy/login-url-helper";

/**
 * We don't need a Policy here as this can be accessed by anyone
 */
export const loader = (args: LoaderFunctionArgs) => {
  const stage = process.env.STAGE;
  invariant(stage, "STAGE is required");

  const subDomain = stage === "prod" ? "" : `.${stage}`;
  const redirectUrl = getRedirectURL(args);
  const afterSignInURL =
    stage === "local" ? `${redirectUrl}` : `https://apeiron${subDomain}.infinitystudio.ai${redirectUrl}`;

  return json({
    signUpUrl: process.env.CLERK_SIGN_UP_URL,
    afterSignInURL,
    stage,
  });
};

export default function SignInPage() {
  const { signUpUrl, afterSignInURL, stage } = useLoaderData<typeof loader>();

  return (
    <div className="mt-16">
      <SignedIn>
        {stage !== "prod" && (
          <div className="flex flex-col items-center">
            <h1 className="text-center text-2xl font-bold">
              You are signed in but your production session has overridden the __client_uat cookie.
              <br />
              You can either sign into production and come back here or you can clear your cookies and refresh the page.
              <br />
              <br />
              If you are running locally, check to make sure your AWS session has not expired.
              <br />
              <u>Check your console for more information.</u>
            </h1>

            <Button className={"mt-4"} onClick={() => window.location.reload()}>
              Refresh
            </Button>
          </div>
        )}
      </SignedIn>
      <SignIn signUpUrl={signUpUrl} redirectUrl={afterSignInURL} />
      <p className="mt-6 text-center text-sm text-gray-500">
        Using this service confirms acceptance <br />
        of our{" "}
        <a target="_blank" rel="noreferrer" href="https://infinitystudio.ai/terms-of-service" className=" underline">
          terms of service
        </a>
      </p>
    </div>
  );
}
